import React, { Component, useState } from 'react'
import Fuse from 'fuse.js';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import SearchIcon from '@material-ui/icons/Search';
import CustomLabelTranslated, { labelColor, contrastingColorBw } from './labels';
import Collapsible from 'react-collapsible';
import { withTranslation } from "react-i18next";
import { config } from './Constants';
import { Helmet } from 'react-helmet';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { InView } from 'react-intersection-observer';
import { OrderItemWidgetLine } from './utils/Orders'
import { ReactComponent as UnderConstructionLogo } from './data/icons/under-construction.svg';
import { ReactComponent as ReceiptSVG } from './data/icons/receipt.svg';
import { ReactComponent as InfoSVG } from './data/icons/info.svg';
import { BubbleNumber, nItemsOpenOrder } from './utils/Orders'
import { formattedPrice } from './utils/misc'

const ContactFormTranslated = React.lazy(() => import('./ContactForm'));
const OrdersPageTranslated = React.lazy(() => import('./Orders'));
const StarrySkyTheme = React.lazy(() => import('./specialeffects/sky'));
const FallingSnowTheme = React.lazy(() => import('./specialeffects/snow'));
const BakeryTheme = React.lazy(() => import('./specialeffects/bakery'));



// The Menu page is the component that represent the entire menu page, which
// lists the content of the menu and provide navigation features. It takes care
// of loading the correct data and update the subcomponents accordingly.
class MenuPage extends Component {

	constructor(props) {
		super(props);

		var menuId = (props.menuId && props.menuId in props.metadata.menus) ? props.menuId : props.metadata.activeMenu  // else is active-menu

		this.state = {
			// We only store the language that's explicitly selected by the user.
			// If this state is set, it will override the browser or props value
			// provided for the language. The reason we do not just set
			//   this.state.language = this.props.language
			// is that React doesn't recommend to make a state directly deriving from
			// a props, so instead we store just the bit of state that can change in
			// this component (the user clicking on one of the language links).
			selectedLanguage: "",
			menuData: null,
			menuId: menuId,
			// The menu data but in the default language, which is the first language
			// in supported languages (considered as the local default).
			// TODO: maybe we can have an explicit default field and keep the order as
			// the display order in the sidebar.
			// defaultMenuData: null,
		};

		this.loadData = this.loadMenu.bind(this);
		this.handleLanguageChange = this.handleLanguageChange.bind(this);
		this.getMostAppropriateLanguage = this.getMostAppropriateLanguage.bind(this);
		this.toggleOrderView = this.toggleOrderView.bind(this)
	}

	toggleOrderView() {
		this.setState({ showOrders: !this.state.showOrders })
	}

	componentDidMount() {
		this.loadMenu(this.getMostAppropriateLanguage(), data => this.setState({ menuData: data }));
		// this.loadMenu(this.props.metadata.menus[this.state.menuId].supportedLanguages[0].code, data => this.setState({ defaultMenuData: data }));
	}

	loadMenu(lang, callback) {
		fetch(`${config.url.PUBLIC}/restaurants/${this.props.restaurantId}/menus/${this.state.menuId}/${lang}/menu.json`, {
			method: 'get',
		}).then(data => data.json())
			.then(data => callback(data))
	}

	getMostAppropriateLanguage() {
		var lang = "";
		if (this.state.selectedLanguage !== "") {
			lang = this.state.selectedLanguage;
		} else if ("language" in this.props.location) {
			lang = this.props.location.language;
		} else if ("language" in navigator && navigator.language !== "") {
			// The navigator.language returns locales including the country (en-US for example).
			// It's probably overkill to provide localization per country, so we are only interested
			// in the language part.
			lang = navigator.language.substr(0, 2);
		} else {
			lang = "en";
		}

		// If the chosen language is not supported, we fallback to something else.
		if (!this.props.metadata.menus[this.state.menuId].supportedLanguages.some(sl => sl.code === lang)) {
			lang = this.props.metadata.menus[this.state.menuId].supportedLanguages[0].code;
			// TODO: Maybe fallback on english if supported and only then on the first one.
			// 	 Or maybe the first one should always be the most relevant, and it's up
			// 	 to the Restaurant to choose?
		}
		return lang;
	}

	handleLanguageChange(lang) {
		this.setState(
			{ selectedLanguage: lang },
			() => this.loadMenu(
				this.getMostAppropriateLanguage(),
				data => {
					this.setState({ menuData: data });
				})
		);
	}

	render() {
		const onLaptop = (window.innerWidth >= 1025);

		if (this.state.showOrders) return <React.Suspense fallback={<></>}>
			<OrdersPageTranslated restaurantId={this.props.restaurantId} toggleOrderView={this.toggleOrderView} />
		</React.Suspense>

		if (this.state.menuData === null) {
			return <div className="menu-page">
				{this.theme}
				{(this.props.metadata.enableFeedback && this.state.menuData && this.state.menuData.length > 0) &&
					<div className={"contact-main" + (onLaptop ? " lg-screen" : "")} >
						<React.Suspense fallback={<></>}>
							<ContactFormTranslated restaurantId={this.props.restaurantId} />
						</React.Suspense>
					</div>
				}
			</div >
		}

		return <div className={"menu-page " + (this.props.metadata.specialEffects || "")}>
			<React.Suspense fallback={<></>}>
				{(this.props.metadata.specialEffects && this.props.metadata.specialEffects.includes('falling-snow')) && <FallingSnowTheme />}
				{(this.props.metadata.specialEffects && this.props.metadata.specialEffects.includes('starry-sky')) && <StarrySkyTheme />}
				{(this.props.metadata.specialEffects && this.props.metadata.specialEffects.includes('bakery')) && <BakeryTheme />}
			</React.Suspense>
			<MenuList {...this.props}
				sections={this.state.menuData}
				// defaultMenu={this.state.defaultMenuData}
				supportedLanguages={this.props.metadata.menus[this.state.menuId].supportedLanguages}
				language={this.getMostAppropriateLanguage()}
				onLanguageChange={this.handleLanguageChange}
				toggleOrderView={this.toggleOrderView}
				menuId={this.state.menuId} />
			{
				(this.props.metadata.enableFeedback && this.state.menuData.length > 0) && <div className={"contact-main" + (onLaptop ? " lg-screen" : "")} >
					<React.Suspense fallback={<></>}>
						<ContactFormTranslated restaurantId={this.props.restaurantId} />
					</React.Suspense>
				</div>
			}
			{
				(this.props.metadata.include_watermark === undefined || this.props.metadata.include_watermark) && <div className={"watermark" + (onLaptop ? " lg-screen" : "")}>
					<div className="watermark-container">
						<a href="https://www.takeaseat.io" target="_blank">Created with www.takeaseat.io</a>
					</div>
				</div>
			}
		</div >;
	}
}

function UnderConstruction({ supportedLanguages, onLanguageChange, i18n, t, language }) {
	return <div className="under-construction">
		<div>{t('empty.0')}</div>
		<div className="under-construction-logo"><UnderConstructionLogo /></div>
		<div>
			{(supportedLanguages.length > 1) && <div>
				<div>{t('empty.1')}</div>
				<div className="available-languages">{
					supportedLanguages.map(
						lang => lang["code"] === language ? <></> : <div
							key={`construct-lang-${lang['code']}`}
							className="available-language clickable"
							onClick={() => {
								onLanguageChange(lang.code);
								i18n.changeLanguage(lang.code);
								window.gtag('event', 'switch-language', { 'event_category': 'Menu', 'event_label': lang.code });
							}}
						>
							<div>{lang["name"]}</div>
							<ExitToAppIcon />
						</div>
					)
				}</div>
			</div>}
		</div>
	</div>
}

function ListLabels({ labels, isSet, toggle, childkey }) {
	// TODO : Do unique key
	return <ul className={"labels"}>
		{labels.map((label, i) => (
			<li className={"label clickable"} key={`${childkey}-${i}`}>
				<CustomLabelTranslated labelName={label} labelDescription={label} isSet={isSet(label)} callback={toggle} />
			</li>
		))}
	</ul>
}

function Filters({ allLabels, isSet, toggle }) {
	// TODO : Do unique key
	return <ul className={"filters"}>
		{allLabels.map(label => (
			<li className={"filter" + (isSet(label) ? " selected" : "")}
				style={{
					backgroundColor: (isSet(label) && labelColor(label)),
					color: (isSet(label) && contrastingColorBw(labelColor(label))) // Black or white depending on backgroubd color
				}}
				onClick={() => toggle(label)} >
				<div >{label}</div>
			</li>
		))}
	</ul>
}

function atLeastOneOption(options) {
	var result = false;
	options.forEach((option) => {
		result = result || option.prefix || option.amount || option.suffix
	})
	return result
}

const LazyPicture = ({ src, alt }) => {
	const [load, setLoad] = useState(false)

	return <>
		<div style={{ marginTop: "0.3rem" }}></div>
		<InView rootMargin={"0px 0px 750px 0px"} delay={0} className="lazy-image-container" as="div" triggerOnce={true} threshold={0} onChange={(inView, entry) => setLoad(inView)}>
			{load ? <img src={`${encodeURI(src)}`} className="lazy-image" alt={alt} /> : <div className="lazy-image-placeholder"><div>Loading...</div></div>}
		</InView>
	</>
}

const Item = React.memo(function Item({ item, restaurantId, openImage, filterIsSet, toggleFilter, t, orderCallback }) {
	var prices = [];
	item.price.forEach((p, i) => prices.push(<li key={`${item.id}-price-${i}`}>{formattedPrice(p)}</li>))

	const priceElement = <>
		<ul>{prices}</ul>
		{(item.price.length > 1) && <div style={{ marginBottom: "0.5rem" }}></div>}
	</>

	return (
		<li>
			<div className={"item"} style={{ marginBottom: "1.4rem" }}>
				<table style={{ width: "100%", marginBottom: "0" }}>
					<tbody>
						<tr>
							<td className={"name"}>
								<div className="item-name" style={{ position: "relative" }}>
									{item.name}
									{orderCallback && <><span
										style={{ fontSize: "80%", fontWeight: "normal" }}>
										{" - order now"}
									</span>
										<OrderItemWidgetLine item={item} restaurantId={restaurantId} callback={orderCallback} /></>}
								</div>
								<ListLabels childkey={item.id + "-labels"} labels={item.labels} isSet={filterIsSet} toggle={toggleFilter} />
							</td>
							{(item.price.length > 0) && <td className={"price"} >{priceElement}</td>}
						</tr>
						{item.description &&
							<tr>
								<td colSpan="2" style={{ position: "relative" }}>
									<div className={"description"}>{item.description}</div>
									{orderCallback && <OrderItemWidgetLine item={item} restaurantId={restaurantId} callback={orderCallback} />}
								</td>
							</tr>
						}
						<tr className="options-row">
							<td colSpan="2" style={{ position: "relative" }}>
								{atLeastOneOption(item.options) ? <>
									{orderCallback && <OrderItemWidgetLine item={item} restaurantId={restaurantId} callback={orderCallback} />}
									<div style={{ display: "flex", marginTop: "0.5rem" }}>
										<div style={{ fontWeight: "bold" }}>Options:</div>
										<ul className={"options"}>
											{item.options.map((option, i) =>
												<li key={`${item.id}-option-${i}`}>{formattedPrice(option)}</li>
											)}
										</ul>
									</div>
								</>
									: null
								}
							</td>
						</tr>
						<tr>
							<td colSpan="2" style={{ position: "relative" }}>{("notes" in item) && (item.notes !== "") ? (
								<>
									{orderCallback && <OrderItemWidgetLine item={item} restaurantId={restaurantId} callback={orderCallback} />}
									<div className={"notes"}>{item.notes}</div>
								</>) : null}</td>
						</tr>
					</tbody>
				</table>
				{(item.image) && <div style={{ position: "relative" }}>
					{orderCallback && <OrderItemWidgetLine item={item} restaurantId={restaurantId} callback={orderCallback} />}
					<LazyPicture src={`${config.url.PUBLIC}/restaurants/${restaurantId}/images/${item.image}`} alt={item.name} />
				</div>}
			</div>
		</li>
	);
})

function isEmptyItem(item) {
	return (
		item.name === "" &&
		item.description === "" &&
		item.price.length === 0 &&
		item.labels.length === 0 &&
		item.options.length === 0 &&
		item.notes === ""
	)
}

function Subsection({ subsection, section, restaurantId, t, filterIsSet, toggleFilter, orderCallback }) {
	return (
		<li>
			<Collapsible
				trigger={
					<div className="title clickable">
						<div style={{ display: "flex", margin: "auto", position: "relative" }}>
							{(subsection.name) ? <>
								<h3>{subsection.name}</h3>
								<div className="section-collapser" style={{ position: "absolute", right: "-2rem", top: "1rem" }}><ExpandLessIcon /></div>
							</> : <div style={{ margin: "2rem 0" }}><div className="section-collapser"><ExpandLessIcon /></div></div>}
						</div>
					</div>
				}
				open={true}>
				<a id={section.id + "-" + subsection.id} className="anchor"></a>
				{subsection.image && <div style={{ margin: "0 auto 1rem auto", display: "flex" }}>
					<img style={{ maxWidth: "100%", maxHeight: "8rem", margin: "auto" }} src={`${config.url.PUBLIC}/restaurants/${restaurantId}/images/${subsection.image}`} alt={`Image of ${subsection.name}`} />
				</div>}
				{subsection.description ? (<div className={"section-description"}>
					<div><InfoSVG name="custom-svg" height="0.8em" width="0.8em" /> </div>
					<div>{subsection.description}</div>
				</div>) : null}
				<ul className={"items"}>
					{subsection.items.map((item, index) => (
						(!isEmptyItem(item) && (!item.hide)) && <Item key={item.id} t={t} item={item} restaurantId={restaurantId} openImage={(index === 0)} filterIsSet={filterIsSet} toggleFilter={toggleFilter} orderCallback={orderCallback} />
					))}
				</ul>
				{subsection.footnote ? (<div className={"footnote"}>
					<div><InfoSVG name="custom-svg" height="0.8em" width="0.8em" /> </div>
					<div>{subsection.footnote}</div>
				</div>) : null}
			</Collapsible>
		</li>
	)
}


const Section = ({ section, t, restaurantId, filterIsSet, toggleFilter, orderCallback }) => (
	<li>
		<Collapsible trigger={
			<div className="title clickable">
				<div style={{ display: "flex", margin: "auto", position: "relative" }}>
					{(section.name) ? <>
						<h2>{section.name}</h2>
						<div className="section-collapser" style={{ position: "absolute", right: "-2rem", top: "2.2rem" }}><ExpandLessIcon /></div>
					</> : <div style={{ margin: "2rem 0" }}><div className="section-collapser"><ExpandLessIcon /></div></div>}
				</div>
			</div>
		} open={true}>
			<a id={section.id} className="anchor"></a>
			{section.image && <div style={{ margin: "0 auto 1rem auto", display: "flex" }}>
				<img style={{ maxWidth: "100%", maxHeight: "8rem", margin: "auto" }} src={`${config.url.PUBLIC}/restaurants/${restaurantId}/images/${section.image}`} alt={`Image of ${section.name}`} />
			</div>}
			{section.description ? (<div className={"section-description"}>
				<div><InfoSVG name="custom-svg" height="0.8em" width="0.8em" /></div>
				<div> {section.description}</div>
			</div>) : null}
			<ul className={"items"}>
				{section.items.map((item, index) => (
					(!isEmptyItem(item) && (!item.hide)) && <Item key={item.id} t={t} item={item} restaurantId={restaurantId} openImage={index === 0} filterIsSet={filterIsSet} toggleFilter={toggleFilter} orderCallback={orderCallback} />
				))}
			</ul>
			<ul className={"subsections"}>
				{section.subsections.map(subsection => (
					(!subsection.hide) && <Subsection key={subsection.id} t={t} subsection={subsection} section={section} restaurantId={restaurantId} filterIsSet={filterIsSet} toggleFilter={toggleFilter} orderCallback={orderCallback} />
				))}
			</ul>
			{section.footnote ? (<div className={"footnote"}>
				<div><InfoSVG name="custom-svg" height="0.8em" width="0.8em" /></div>
				<div> {section.footnote}</div>
			</div>) : null}
		</Collapsible>
	</li>
);

const ListSections = ({ sections, t, restaurantId, toggleFilter, filterIsSet, orderCallback }) => (
	<ul className={"sections"}>
		{sections.map(section => (
			(!section.hide) && <Section key={section.id} section={section} t={t} restaurantId={restaurantId} toggleFilter={toggleFilter} filterIsSet={filterIsSet} orderCallback={orderCallback} />
		))}
	</ul>
);

// This component displays and filters a constant menu.
class MenuList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// TODO: create object with explict include/exclude.
			//       Currently if key -> true, it means key is included, and
			//       if key -> false, it means key is excluded, and if key not
			//       in filters, then it's neither included nor excluded.
			selectedFilters: Object(),
			searchKey: '',
			showFilterBar: false,
			bookmarks: Object(),
			drawerState: false,
			showLogo: true,
			nItemsOpenOrder: nItemsOpenOrder()
		}

		if ("bookmarks" in props.location) {
			// TODO(Regis): I think this is not safe, sometimes the constructor is skipped when
			// props change, so the state should not be initialized by a props value.
			// See https://medium.com/@justintulk/react-anti-patterns-props-in-initial-state-28687846cc2e for background.
			this.state.bookmarks = props.location.bookmarks;
		}

		this.searchInput = React.createRef();
		this.hideSearchKeyboard = this.hideSearchKeyboard.bind(this);

		this.filterIsSet = this.filterIsSet.bind(this);
		this.setFilter = this.setFilter.bind(this);
		this.selectFilter = this.selectFilter.bind(this);
		this.toggleFilter = this.toggleFilter.bind(this);
		this.searchAndFilterItems = this.searchAndFilterItems.bind(this);
		this.gatherFuseResultItems = this.gatherFuseResultItems.bind(this);
		this.filterItems = this.filterItems.bind(this);
	}

	hideSearchKeyboard() {
		this.searchInput.current.blur();
	}

	filterIsSet(label) {
		return label in this.state.selectedFilters
	}

	setFilter(label) {
		window.gtag('event', 'filter', { 'event_category': 'Menu', 'event_label': label });
		this.setState(prevState => {
			let sf = { ...prevState.selectedFilters };
			sf[label] = true;
			return { selectedFilters: sf, showFilterBar: true };
		})
	}

	toggleFilterBar = (e) => {
		this.setState(prevState => {
			return { showFilterBar: !prevState.showFilterBar }
		})
	}

	openFilterBar = (e) => {
		this.setState({ showFilterBar: true })
	}

	closeFilterBar = (e) => {
		this.setState({ showFilterBar: false })
	}

	toggleFilter(label) {
		if (this.filterIsSet(label)) {
			this.setState(prevState => {
				let sf = { ...prevState.selectedFilters };
				delete sf[label];
				return { selectedFilters: sf }; // , showFilterBar: Object.keys(sf).length > 0
			})
		} else {
			this.setFilter(label);
		}
	}

	selectFilter(label) {
		this.setFilter(label);
	}

	searchAndFilterItems(searchKey, filters) {
		const fuse = new Fuse(this.props.sections, {
			keys: [
				'items.name',
				'items.description',
				'subsections.items.name',
				'subsections.items.description'
			],
			includeMatches: true,
			ignoreLocation: true,
			threshold: 0.1,
			shouldSort: false
		});

		var sec = (searchKey === '') ? this.props.sections : this.gatherFuseResultItems(fuse.search(searchKey));
		return this.filterItems(sec, filters);
	}

	gatherFuseResultItems(fuseResult) {
		var sections = []
		for (var i = 0; i < fuseResult.length; i++) {
			var section = fuseResult[i].item;
			// The matches can contain multiple reference to the same item
			// (for example if it matches in name and in description) and
			// the order of matches is not guaranteed. It seems like the
			// shouldSort option given to Fuse only applies to the result
			// list, but not to the matches object.
			var matches = fuseResult[i].matches;
			// We accumulate indexes that were matched, and then we filter
			// the original items in order to keep the ordering.
			var itemIndexes = new Set();
			var subsectionItemIndexes = new Set();

			for (let j = 0; j < matches.length; j++) {
				if (matches[j].key.startsWith("items")) {
					itemIndexes.add(matches[j].refIndex);
				} else {
					subsectionItemIndexes.add(matches[j].refIndex);
				}
			}

			let items = [];
			for (let j = 0; j < section.items.length; j++) {
				if (itemIndexes.has(j)) {
					items.push(section.items[j]);
				}
			}

			var subsections = [];
			// The matches return refIndex that are global to all subsections (not reset
			// to 0 for each new subsection) so we need to increment it for each item inside
			// each of the subsection, and then compare it to the collected subsection
			// item indices from the matches data structure.
			var subsectionItemIndex = 0;
			section.subsections.forEach(subsection => {
				let items = [];
				subsection.items.forEach(i => {
					if (subsectionItemIndexes.has(subsectionItemIndex)) {
						items.push(i);
					}
					subsectionItemIndex += 1;
				})

				if (items.length > 0) {
					subsections.push({
						id: subsection.id,
						name: subsection.name,
						description: section.description,
						footnote: section.footnote,
						items: items
					})
				}
			})

			sections.push({
				id: section.id,
				name: section.name,
				description: section.description,
				footnote: section.footnote,
				items: items,
				subsections: subsections
			});
		}

		return sections
	}

	filterItems(sections, filters) {
		if (Object.keys(filters).length === 0)
			return sections
		return sections.map(section => ({
			id: section.id,
			name: section.name,
			description: section.description,
			footnote: section.footnote,
			items: section.items.filter(item =>
				item.labels.some(label => (label in filters) && filters[label])),
			subsections: section.subsections.map(subsection => ({
				id: subsection.id,
				name: subsection.name,
				description: subsection.description,
				footnote: subsection.footnote,
				items: subsection.items.filter(item =>
					item.labels.some(label => (label in filters) && filters[label])),
			})).filter(subsection => subsection.items.length > 0)
		})).filter(section => section.items.length > 0 || section.subsections.length > 0);
	}

	_handleSearchKeypress = (e) => {
		if (e.key === 'Enter') {
			this.hideSearchKeyboard();
		}
	}

	render() {
		// TODO: We should refactor this using memoization (it only
		// needs to be recomputed when the sections changes).
		var allLabels = Array.from(new Set(
			this.props.sections.map(section =>
				section.items.map(item => item.labels).concat(
					section.subsections.map(subsection => subsection.items.map(item => item.labels).flat()).flat()).flat()).flat()
		));
		allLabels.sort()

		const filteredSections = this.searchAndFilterItems(this.state.searchKey, this.state.selectedFilters);

		const { t, i18n } = this.props;

		const onLaptop = (window.innerWidth >= 1025);

		return <div className={onLaptop ? "lg-screen" : ""} style={{ zIndex: "999" }}>
			<Helmet htmlAttributes={{ dir: ["fa", "ar"].includes(this.props.language) ? "rtl" : "ltr" }}>
				<title>{this.props.metadata.name || this.props.metadata.alias || "Take a Seat"} | Menu</title>
				<meta name="description" content={`Menu of ${this.props.metadata.name || this.props.metadata.alias || this.props.restaurantId}`} />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={`Menu of ${this.props.metadata.name || this.props.metadata.alias || this.props.restaurantId}`} />
				<meta property="og:description" content={`Online menu of ${this.props.metadata.name || this.props.metadata.alias || this.props.restaurantId}`} />
				<meta property="og:url" content={`${config.url.GUEST}/${this.props.metadata.alias || this.props.restaurantId}/menu`} />
				<meta property="og:site_name" content={this.props.metadata.name || this.props.metadata.alias || this.props.restaurantId} />
				<link rel="icon" type="image/png" href={
					(this.props.metadata.logo && this.props.metadata.logo.src) ? `${config.url.PUBLIC}/restaurants/${this.props.restaurantId}/${this.props.metadata.logo.src}`
						: `${process.env.PUBLIC_URL}/favicon.ico`
				} sizes="52x52" />
			</Helmet>
			<React.Fragment>
				<AppBar position="sticky" tabIndex="-1" >
					<Toolbar>
						{!onLaptop && <MenuIcon onClick={() => this.setState({ drawerState: true })} />} {/** On laptop we show drawer */}
						<div style={{ display: "flex", margin: "auto", width: "100%" }}>
							<div className="appbar-searchbar">
								<SearchIcon style={{ color: "black", margin: "auto 0 auto 0.3rem" }} />
								<input type="text" ref={this.searchInput}
									onFocus={this.openFilterBar}
									onChange={(e) => {
										this.setState({ searchKey: e.target.value })
										// TODO: Use some timeout to only log event if no character added within X seconds.
										window.gtag('event', 'search', { 'event_category': 'Menu', 'event_label': e.target.value });
									}}
									placeholder={t('search-menu')}
									onKeyPress={this._handleSearchKeypress}
								/>
							</div>
							{this.props.metadata.ordering && <div style={{ marginLeft: "0.2rem", display: "flex", alignItems: "center" }} className="clickable">
								<div onClick={this.props.toggleOrderView} style={{ position: "relative" }}>
									<ReceiptSVG style={{ height: "1.5rem", width: "1.5rem", fill: "currentcolor" }} />
									{this.state.nItemsOpenOrder ? <BubbleNumber number={this.state.nItemsOpenOrder} variant={"warning"} /> /* Show bubble order only if greater than 0*/ : null}
								</div>
								{/*	{(allLabels.length > 0) && <FilterAltIcon onClick={this.toggleFilterBar} />
								}*/}
							</div>}
						</div>
					</Toolbar>
					{this.state.showFilterBar && allLabels.length > 0 ?
						<div className={"filters-appbar"}>
							<Filters allLabels={allLabels} isSet={this.filterIsSet} toggle={this.toggleFilter} />
							<div onClick={this.closeFilterBar}><ExpandLessIcon /></div>
						</div>
						: null
					}
				</AppBar>
			</React.Fragment>
			<React.Fragment key={'left'}>
				<Drawer
					className={onLaptop ? "lg-screen" : ""}
					disableEnforceFocus={true}
					id={'main-drawer'} anchor={'left'} open={this.state.drawerState || onLaptop} onClose={() => this.setState({ drawerState: false })}>
					{(this.state.showLogo) ?
						<img
							style={{ maxWidth: "16rem", width: "50%", margin: "2rem auto", marginBottom: "1rem" }}
							alt={this.props.metadata.logo.alt}
							data-type="image"
							itemProp="image"
							src={`${config.url.PUBLIC}/restaurants/${this.props.restaurantId}/${this.props.metadata.logo.src}`}
							onError={(e) => {
								this.setState({ showLogo: false })
							}} className="sidebar-logo" />
						:
						<div style={{ margin: "1rem" }}></div>
					}
					<ul className={"drawer-sections"}>
						{this.props.sections.map(section => (
							<h3 key={`drawer-${section.id}`}>
								<a href={"#" + section.id} onClick={() => this.setState({ drawerState: false })}>{section.name}</a>
								{section.subsections.map(subsection => (
									<h4 key={`drawer-${subsection.id}`} style={{ margin: "0.8rem" }}>
										<a href={"#" + section.id + "-" + subsection.id} onClick={() => this.setState({ drawerState: false })}>{subsection.name}</a>
									</h4>
								))}
							</h3>
						))}
					</ul>
					<div className="bottom-elements">
						{(this.props.metadata.menus[this.props.metadata.activeMenu].supportedLanguages.length > 1) &&
							<Collapsible className="languages clickable" openedClassName="languages clickable" trigger={<div style={{ marginBottom: "1ch" }}>{t('languages')}</div>}>
								<ul>
									{this.props.metadata.menus[this.props.metadata.activeMenu].supportedLanguages.map(lang => (
										<li key={`lang-${lang.code}`} className={"language clickable" + (lang.code === this.props.language ? " language-selected" : "")} onClick={() => {
											this.props.onLanguageChange(lang.code);
											i18n.changeLanguage(lang.code);
											this.setState({ drawerState: false });
											window.gtag('event', 'switch-language', { 'event_category': 'Menu', 'event_label': lang.code });
										}}>
											{lang.name}
										</li>
									))}
								</ul>
							</Collapsible>}
						{this.props.metadata.enableFeedback && <div className="contact-drawer">
							<React.Suspense fallback={<></>}>
								<ContactFormTranslated restaurantId={this.props.restaurantId} />
							</React.Suspense>
						</div>}
					</div>
				</Drawer>
			</React.Fragment>
			<section>
				{(this.state.showLogo) &&
					<div><b>Hello</b>
						<img
							style={{ maxWidth: "18rem", width: "50%", margin: "2rem auto 0 auto" }}
							alt={this.props.metadata.logo.alt}
							data-type="image"
							itemProp="image"
							src={`${config.url.PUBLIC}/restaurants/${this.props.restaurantId}/${this.props.metadata.logo.src}`}
							onError={(e) => {
								this.setState({ showLogo: false })
							}} className="menu-logo" />
					</div>
				}
				{this.props.sections.length === 0 ?
					<UnderConstruction
						t={t}
						i18n={i18n}
						onLanguageChange={this.props.onLanguageChange}
						supportedLanguages={this.props.metadata.menus[this.props.metadata.activeMenu].supportedLanguages}
						language={this.props.language}
					/>
					: <ListSections sections={filteredSections} t={t} toggleFilter={this.toggleFilter} filterIsSet={this.filterIsSet} restaurantId={this.props.restaurantId} orderCallback={(this.props.metadata.ordering) ? ((nItemsOpenOrder) => this.setState({ nItemsOpenOrder })).bind(this) : undefined} />
				}
			</section>
		</div>
	}
}

const MenuPageTranslated = withTranslation('common')(MenuPage)

export default MenuPageTranslated

import React, { Component, useState } from 'react'
import _ from 'lodash'; // optimize import
import { config } from '../Constants';
import Modal from '@material-ui/core/Modal';
import ClearIcon from '@material-ui/icons/Clear'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import './Orders.css'
import CheckIcon from '@mui/icons-material/Check';
import { Link } from 'react-router-dom';
import { formattedPrice } from './misc'
var QRCode = require('qrcode.react');


const deviceStorage = window.localStorage;

export function ValidationQRcode({ orderId }) {
    return <div><QRCode
        // size={this.qrcodeW}
        includeMargin={false}
        level="L"
        renderAs="svg"
        /*imageSettings={{
            height: 80, width: 80, excavate: this.props.middleFigure // To have a clean crop. Unfortunately, src will not work in our case
        }}*/
        value={`https://guest.takeaseat.io/order-validation/${orderId}`} />
        <div style={{fontSize: "80%", textAlign: "center"}}>Scan to verify</div>
        </div>
}

export function nItemsOpenOrder() {
    const openOrder = JSON.parse(deviceStorage.getItem("openOrder"))

    if (openOrder && openOrder.items) return openOrder.items.length
    return 0
}

export function updateLocalStoreOrderStatus(orderId, newStatus) {
    var openOrders = JSON.parse(deviceStorage.getItem("openOrders")) || []
    const i = _.findIndex(openOrders, (openOrder) => { return openOrder.id === orderId }, 0)

    if (i == -1) return //TODO: this should not happen

    openOrders[i]['status'] = newStatus
    deviceStorage.setItem("openOrders", JSON.stringify(openOrders))
}

export function getOrderStatus({ orderId, callback }) {
    fetch(`${config.url.API}/order/status/${orderId}`, {
        method: 'get',
    }).then(data => data.json())
        .then(data => callback(data))
}

export async function placeOrder({ order, restaurantId }) {
    return fetch(`${config.url.API}/order/${restaurantId}`, {
        method: 'put',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(order)
    })
}

export function BubbleNumber({ number, variant }) {
    return <div className={`bubble-number ${variant}`}>
        {number}
    </div>
}

export function OrderItemWidgetLine({ item, restaurantId, callback }) {
    const [showModal, setShowModal] = useState(false)
    const [showContinueOptions, setShowContinueOptions] = useState(false)
    const [selectedPrice, setSelectedPrice] = useState(0)
    const [selectedOption, setSelectedOption] = useState(0)
    const [quantity, setQuantity] = useState(1)

    const handleClose = () => {
        setShowContinueOptions(false);
        setShowModal(false);
    };

    return <>
        <Modal
            open={showModal}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={"order-modal"}
        ><div className="order-modal-content">
                <div className="close-icon clickable" onClick={handleClose}><ClearIcon /></div>
                <div className="name">
                    <div className="item-name">{item.name}</div>
                    <div className="labels">
                        {(item.labels) && item.labels.join(", ")}
                    </div>
                </div>
                {(item.price && item.price.length > 0) && <div className="order-price"> {/* TODO: handle multiple prices */}
                    <div className="price-label">Prices:</div>
                    <div className="prices">
                        {item.price.map((price, i) => <div
                            className={"price " + (selectedPrice === i ? "selected" : "")}
                            onClick={() => setSelectedPrice(i)}
                        >
                            <div>{formattedPrice(price)}</div>
                            {(selectedPrice === i) ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                        </div>)}
                    </div>
                </div>}
                {(item.options && item.options.length > 0) && <div className="order-option"> {/* TODO: handle options */}
                    <div className="option-label">Options:</div>
                    <div className="options">
                        {item.options.map((option, i) => <div
                            className={"price " + (selectedOption === i ? "selected" : "")}
                            onClick={() => setSelectedOption(i)}
                        >
                            <div>{formattedPrice(option)}</div>
                            {(selectedOption === i) ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                        </div>)}
                    </div>
                </div>}
                <div className="order-quantity"> {/* TODO: handle multiple prices */}
                    <div className="quantity-label">Quantity:</div>
                    <div className="quantity">
                        <select onChange={(e) => setQuantity(e.target.value)}>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                        </select>
                    </div>
                </div>
                {/* TODO: handle options */}
                {/* TODO: handle quantity */}
                <div className="order-actions">
                    {showContinueOptions ? <>
                        <div className="order-success">Successfully added<CheckIcon /></div> {/* TODO: Dynamic */}
                        <div className="order-continue modal-button clickable" onClick={handleClose}>Continue ordering</div>
                        <div className="order-finish underlined-button clickable"><Link to={`/${restaurantId}/orders`}>Finish my order</Link></div> {/* TODO: terminate interface */}
                    </> :
                        <div className="order-add modal-button clickable" onClick={() => {
                            var openOrder = JSON.parse(deviceStorage.getItem("openOrder"))
                            if (!openOrder) openOrder = { "items": [] }

                            openOrder.items.push({ id: item.id, quantity: quantity, labels: item.labels, name: item.name, price: item.price[selectedPrice], options: item.options[selectedOption] })  // TODO: Take value from user selection 
                            deviceStorage.setItem("openOrder", JSON.stringify(openOrder))

                            setShowContinueOptions(true)
                            callback(nItemsOpenOrder())
                        }}>Add to order</div>
                    }
                </div>
            </div>
        </Modal>
        <div style={{ position: "absolute", inset: "0" }} onClick={() => setShowModal(true)}></div>
    </>
}
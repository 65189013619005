export function formattedPrice(price) {
	var pr = (price["prefix"]) ? price["prefix"] : ""
	pr = pr.endsWith(" ") ? pr : (pr + " ")

	var suff = (price["suffix"]) ? price["suffix"] : ""
	suff = suff.startsWith(" ") ? suff : (" " + suff)

	var am = (price["amount"]) ? price["amount"] : ""

	let price_rendered = pr + am + suff;

	return price_rendered;
}
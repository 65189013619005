import React from 'react';
import { Route, Switch } from 'react-router';
import './App.css';
import MenuPageTranslated from './MenuPage';
import { BrowserRouter, Redirect } from "react-router-dom";
import { config } from './Constants';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';
import qs from "qs";

const OrdersPage = React.lazy(() => import('./Orders'));
const OrderValidationPage = React.lazy(() => import('./OrderValidation'));
const RegisterPage = React.lazy(() => import('./RegisterPage'));

function customDomain() {
	return config.url.GUEST !== window.location.origin
}

// Take in a component as argument WrappedComponent
const withMetadata = (WrappedComponent) => {
	// And return another component
	class HOC extends React.Component {

		constructor(props) {
			super(props);
			this.state = {
				metadata: null
			};

			this.loadMetadata = this.loadMetadata.bind(this);
		}

		componentDidMount() {
			this.loadMetadata();
		}

		loadMetadata() {
			var xhr = new XMLHttpRequest();
			xhr.open("GET", `${config.url.PUBLIC}/restaurants/${this.props.restaurantId}/metadata.json`)
			xhr.onload = function (e) {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						var metadata = JSON.parse(xhr.responseText);

						// Has no active menu. This should not be the case if there is at least one menu.
						if (metadata.activeMenu === "" || metadata.menus[metadata.activeMenu] === undefined) {
							metadata.activeMenu = "default"
							metadata.menus["default"] = { "label": "Default", "supportedLanguages": [{ "name": "Anglais", "code": "en" }] }
						}

						// Have at least one supported language
						if (metadata.menus[metadata.activeMenu].supportedLanguages.length === 0) metadata.menus[metadata.activeMenu].supportedLanguages.push({ "code": "en", "name": "English" })
						this.setState({ metadata });
					} else {
						console.error(xhr.statusText);
					}
				}
			}.bind(this);
			xhr.onerror = function (e) {
				console.error(xhr.statusText);
			};
			xhr.send(null);
		}

		logAnalyticsEvent() {
			if (this.props.page) {
				fetch(`${config.url.API}/analytics/visit`, {
					method: 'post',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					credentials: "include",
					body: JSON.stringify({
						customerId: (new Cookies().get('_tas_cid')),
						restaurantId: this.state.metadata.id,
						page: this.props.page,
						source: this.props.source
					})
				});
			}
		}

		render() {
			if (this.state.metadata == null) {
				return <div></div>;
			}

			if (!customDomain() && this.state.metadata.domain) {
				return window.location.assign(`https://guest.${this.state.metadata.domain}/${this.props.location.search}`);
			}

			var urlParts = this.props.location.pathname.split("/")
			if (!customDomain() && this.state.metadata.alias && this.state.metadata.alias != urlParts[1]) {
				urlParts[1] = this.state.metadata.alias;
				const redirectUrl = `${urlParts.join('/')}${this.props.location.search}`;
				return <Redirect to={redirectUrl} />;
			}

			const recursiveProps = { ...this.props };
			delete recursiveProps.restaurantId;

			this.logAnalyticsEvent();

			return <WrappedComponent {...recursiveProps} restaurantId={this.state.metadata.id} metadata={this.state.metadata} />;
		}
	}
	return HOC;
};

// TODO: We load the metadata twice (once for this redirect and then once again after
//       the redirection) so it would be nice to share the metadata across the components instead.
const LandingRedirect = (props) => {
	var landingPath = "menu";
	if (props.metadata.landing === "contact-tracing") {
		landingPath = "register";
	} else if (props.metadata.landing === "menu") {
		landingPath = "menu";
	}

	if (customDomain()) {
		// The restaurant is using its own domain. The redirection should not mention the restaurant name
		return <Redirect to={`/${landingPath}${props.location.search}`} />;
	}
	return <Redirect to={`${props.location.pathname}/${landingPath}${props.location.search}`} />;
}

const RegisterPageTranslatedWithMetadata = withMetadata(RegisterPage)
const MenuPageTranslatedWithMetadata = withMetadata(MenuPageTranslated)
const LandingRedirectWithMetadata = withMetadata(LandingRedirect)

const Main = () => {
	const cookies = new Cookies();
	var id = cookies.get('_tas_cid');
	if (id === undefined) {
		id = uuidv4();
	}
	const current = new Date();
	const nextYear = new Date();
	nextYear.setFullYear(current.getFullYear() + 1);
	cookies.set('_tas_cid', id, { path: '/', expires: nextYear });

	const getSource = function (props) {
		var context = qs.parse(props.location.search, { ignoreQueryPrefix: true }).context;
		var utm_medium = qs.parse(props.location.search, { ignoreQueryPrefix: true }).utm_medium;
		var source
		if (context == 'preview')
			source = 'preview';
		else if (utm_medium == 'qrcode')
			source = 'qrcode';
		else
			source = 'direct';
		return source;
	};

	const getMenuId = function (props) {
		return qs.parse(props.location.search, { ignoreQueryPrefix: true }).menu;
	}

	if (customDomain()) {
		const restaurantId = window.location.hostname.substring(6) // we remove 'guest.' in front of top level domain

		return <BrowserRouter>
			<Route path="/" render={({ location }) => {
				if (typeof window.gtag === 'function') {
					window.gtag('event', 'page_view', {
						page_path: location.pathname + location.search
					});
				}
				return null;
			}} />
			<Switch>
				<Route exact
					path="/order-validation/:orderId"
					render={(props) => {
						return <React.Suspense fallback={<></>}>
							<OrderValidationPage orderId={props.match.params.orderId} />
						</React.Suspense>
					}} />
				<Route exact
					path="/"
					render={(props) => {
						return <LandingRedirectWithMetadata {...props} menuId={getMenuId(props)} source={getSource(props)} restaurantId={restaurantId} />
					}} />
				<Route exact
					path="/orders"
					render={(props) => {
						return <>
							<React.Suspense fallback={<></>}>
								<link rel="stylesheet" type="text/css" href={`${config.url.PUBLIC}/restaurants/${restaurantId}/themes/default/theme.css`} />
								<OrdersPage {...props} menuId={getMenuId(props)} source={getSource(props)} restaurantId={restaurantId} />
							</React.Suspense>
						</>
					}} />
				<Route exact
					path="/register"
					render={(props) => {
						return <React.Suspense fallback={<></>}>
							<RegisterPageTranslatedWithMetadata {...props} menuId={getMenuId(props)} source={getSource(props)} page='contact_tracing' restaurantId={restaurantId} />
						</React.Suspense>
					}} />
				<Route exact
					path="/menu"
					render={(props) => {
						return <>
							<link rel="stylesheet" type="text/css" href={`${config.url.PUBLIC}/restaurants/${restaurantId}/themes/default/theme.css`} />
							<MenuPageTranslatedWithMetadata {...props} menuId={getMenuId(props)} source={getSource(props)} page='menu' restaurantId={restaurantId} />
						</>
					}} />
			</Switch>
		</BrowserRouter>
	} else {
		return <BrowserRouter>
			<Route path="/" render={({ location }) => {
				if (typeof window.gtag === 'function') {
					window.gtag('event', 'page_view', {
						page_path: location.pathname + location.search
					});
				}
				return null;
			}} />
			<Switch>
				<Route exact
					path="/order-validation/:orderId"
					render={(props) => {
						return <React.Suspense fallback={<></>}>
							<OrderValidationPage orderId={props.match.params.orderId} />
						</React.Suspense>
					}} />
				<Route exact
					path="/:restaurantId"
					render={(props) => {
						return <LandingRedirectWithMetadata {...props} customDomain={true} menuId={getMenuId(props)} source={getSource(props)} restaurantId={props.match.params.restaurantId} />
					}} />
				<Route exact
					path="/:restaurantId/orders"
					render={(props) => {
						return <>
							<React.Suspense fallback={<></>}>
								<link rel="stylesheet" type="text/css" href={`${config.url.PUBLIC}/restaurants/${props.match.params.restaurantId}/themes/default/theme.css`} />
								<OrdersPage {...props} menuId={getMenuId(props)} source={getSource(props)} restaurantId={props.match.params.restaurantId} />
							</React.Suspense>
						</>
					}} />
				<Route exact
					path="/:restaurantId/register"
					render={(props) => {
						return <React.Suspense fallback={<></>}>
							<RegisterPageTranslatedWithMetadata {...props} menuId={getMenuId(props)} source={getSource(props)} page='contact_tracing' restaurantId={props.match.params.restaurantId} />
						</React.Suspense>
					}} />
				<Route exact
					path="/:restaurantId/menu"
					render={(props) => {
						return <>
							<link rel="stylesheet" type="text/css" href={`${config.url.PUBLIC}/restaurants/${props.match.params.restaurantId}/themes/default/theme.css`} />
							<MenuPageTranslatedWithMetadata {...props} menuId={getMenuId(props)} source={getSource(props)} page='menu' restaurantId={props.match.params.restaurantId} />
						</>
					}} />
			</Switch>
		</BrowserRouter>
	}
};

export default Main;

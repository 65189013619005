import React from 'react'
import Popover from '@material-ui/core/Popover';
import { v4 as uuidv4 } from 'uuid';
import { withTranslation } from "react-i18next";


const labelcolors = {
	'vegetarian'   : "#70946e",
	'vegetariano'  : "#70946e",
	'végétarien'   : "#70946e",
	'vegetarisch'  : "#70946e",
	'vegetariano'  : "#70946e",
	'素食'         : "#70946e",

	'vegan'        : "#9ccd97",
	'vegano'       : "#9ccd97",
	'vegan'        : "#9ccd97",
	'vegan'        : "#9ccd97",
	'vegano'       : "#9ccd97",
	'纯素食者'     : "#9ccd97",

	'nuts free'    : "#793c06",
	'sin nueces'   : "#793c06",
	'sans noix'    : "#793c06",
	'nussfrei'     : "#793c06",
	'senza noci'   : "#793c06",
	'坚果免费'     : "#793c06",

	// TODO: add as example label in admin? maybe.
	'dairy free'   : "#7f0bf6",

	'gluten free'  : "#ffc43c",
	'sin gluten'   : "#ffc43c",
	'sans gluten'  : "#ffc43c",
	'glutenfrei'   : "#ffc43c",
	'senza glutine': "#ffc43c",
	'不含麸质'     : "#ffc43c",

	'meat'         : "#de5442",
	'carne'        : "#de5442",
	'viande'       : "#de5442",
	'Fleisch'      : "#de5442",
	'carne'        : "#de5442",
	'肉类'         : "#de5442",

	'chicken'      : "#f7a1bc",
	'pollo'        : "#f7a1bc",
	'poulet'       : "#f7a1bc",
	'Hähnchen'     : "#f7a1bc",
	'pollo'        : "#f7a1bc",
	'鸡肉'         : "#f7a1bc",

	'fish'         : "#a5cee3",
	'pescado'      : "#a5cee3",
	'poisson'      : "#a5cee3",
	'Fisch'        : "#a5cee3",
	'pesce'        : "#a5cee3",
	'鱼类'         : "#a5cee3"
}

// The total number of color combination with the hex notation.
const totalNumberColors = 16777216;

function hashCode(str) {
  var hash = 0, i, chr;
  for (i = 0; i < str.length; i++) {
                chr   = str.charCodeAt(i);
                hash  = ((hash << 5) - hash) + chr;
                hash |= 0; // Convert to 32bit integer
              }
  return hash;
}

/* Create utils file for managing colors */

function randomColor(str) {
  const hash = hashCode(str);
  const color = ("000000"+hash.toString(16)).substr(-6);
  return '#'+color;
}

export function labelColor(label){
	return (labelcolors[label.toLowerCase()]) ? labelcolors[label.toLowerCase()] : randomColor(label.toLowerCase())
}

export function contrastingColorBw(color){
    var color = (color.charAt(0) === '#') ? color.substring(1, 7) : color;

    var r = parseInt(color.slice(0, 2), 16),
        g = parseInt(color.slice(2, 4), 16),
        b = parseInt(color.slice(4, 6), 16);

	// http://stackoverflow.com/a/3943023/112731
	return (r * 0.299 + g * 0.587 + b * 0.114) > 180
		? '#000000'
		: '#FFFFFF';
}

function CustomLabel({ t, i18n, labelDescription, labelName, isSet, that, callback, showLabel = false }) {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? uuidv4() : undefined;

	return (
		<div style={{ display: "flex", height: "fit-content" }}>
			<div onClick={handleClick} aria-describedby={id} style={{ margin: "auto", display: "flex" }}>
				{(showLabel ?
					<label>{labelName}</label>
					: null
				)}
				{(labelcolors[labelName.toLowerCase()]) ?
					<div style={{ width: "2.5rem", height: "0.4rem", backgroundColor: labelcolors[labelName.toLowerCase()], borderRadius: "2rem", margin: "auto", marginRight: "0.1rem" }}></div>
					:
					<div style={{ width: "2.5rem", height: "0.4rem", backgroundColor: randomColor(labelName.toLowerCase()), borderRadius: "2rem", margin: "auto", marginRight: "0.1rem" }}></div>
				}
			</div>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
			>
				<ul style={{ padding: "0 1rem" }}>
					<li style={{ listStyle: "none", paddingBottom: "0.5rem", fontStyle: "italic" }}>
						{labelDescription}
					</li>
					<li style={{ listStyle: "none" }} onClick={() => { callback(labelName, that); setAnchorEl(null); }}>
						{isSet ? t("filters.remove") : t("filters.set")}
					</li>
				</ul>
			</Popover>
		</div>
	)
}


const CustomLabelTranslated = withTranslation('common')(CustomLabel)

export default CustomLabelTranslated;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './routes';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_en from "./translations/en/common.json";
import common_es from "./translations/es/common.json";
import common_fr from "./translations/fr/common.json";
import common_de from "./translations/de/common.json";
import common_zh from "./translations/zh/common.json";
import common_it from "./translations/it/common.json";
import common_fa from "./translations/fa/common.json";
import common_ar from "./translations/ar/common.json";
import { bestLanguage } from "./utils/LanguageUtils";


const lang = bestLanguage(true, null, ["en", "fr", "de", "es", "it", "fa", "ar"])

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: lang,
  resources: {
    en: {
      common: common_en
    },
    es: {
      common: common_es
    },
    fr: {
      common: common_fr
    },
    de: {
      common: common_de
    },
    zh: {
      common: common_zh
    },
    it: {
      common: common_it
    },
    fa: {
      common: common_fa
    },
    ar: {
      common: common_ar
    }
  },
  fallbackLng: {
    'default': ['en']
  }
});


ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Main />
  </I18nextProvider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
